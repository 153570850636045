.projects {
    padding-top: 120px;
    margin-top: -120px;
    scroll-margin-top: 120px;
}

.project-container {
    overflow: initial;
    margin-top: 40px;
}

.project-content {
    padding: 0 200px;
}

.project-img {
    border-radius: 20px;
    max-width: 750px;
}

.project-title {
    margin-top: 15px;
    margin-bottom: 5px;
}

.project-description {
    margin-bottom: 30px !important;
    margin-top: 10px !important;
}

.project-button:hover .button-icon {
    transform: translateX(5px);
}

.button-icon {
    font-size: 25px;
    margin-left: 5px;
    transition: .4s;
}

.project-button {
    display: flex;
    align-items: center;
    width: min-content;
    margin: auto auto 50px auto !important;
    padding: 10px 20px;
}

.project-button span {
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.swiper-button-next::after, .swiper-button-prev::after {
    content: '' !important;
}

.swiper-button-next, .swiper-button-prev {
    color: #fff !important;
    font-size: 50px;
    width: 50px !important;
    height: 50px !important;
    transition: .4s;
}

.swiper-button-next {
    margin-right: 100px;
}

.swiper-button-next:hover {
    transform: translateX(10px);
}

.swiper-button-prev {
    margin-left: 100px;
}

.swiper-button-prev:hover {
    transform: translateX(-10px);
}

.swiper-pagination-bullet {
    background-color: #fff !important;
    width: 10px !important;
    height: 10px !important;
}

@media (max-width: 1200px) {
    .project-content {
        padding: 0 100px;
    }

    .project-img {
        max-width: 500px;
    }

    .swiper-button-next, .swiper-button-prev {
        font-size: 40px;
    }

    .swiper-button-next {
        margin-right: 30px;
    }

    .swiper-button-prev {
        margin-left: 30px;
    }
}

@media (max-width: 768px) {
    .project-content {
        padding: 0 10px;
    }

    .swiper-button-next, .swiper-button-prev, .swiper-button-next::after, .swiper-button-prev::after {
        display: none !important;
    }

    .project-button {
        padding: 7px 15px;
    }

    .swiper-pagination-bullet {
        width: 6px !important;
        height: 6px !important;
    }

    .project-subtitle {
        font-size: 14px;
    }

    .project-description {
        font-size: 14px;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}